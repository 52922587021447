<template>
  <div class="d-flex flex-column">
    <template>
      <v-toolbar class="bridge-toolbar d-flex justify-space-between align-center pt-6" outlined elevation="1" flat>
        <v-btn class="ml-10 mb-3" color="blue" small outlined :to="{ name: 'bridge-management-map' }">{{ $t("map-view")
        }}</v-btn>
        <v-btn class="mx-2 mb-3" color="blue" active-class small outlined :to="{ name: 'bridge-management-list' }">{{
          $t("list-view") }}</v-btn>
      </v-toolbar>
    </template>
    <router-view :bridges="bridges"> </router-view>
  </div>
</template>


<script>
import BridgeService from "@/services/BridgeService";
export default {
  name: "bridges",
  data: () => ({
    hi: null,
    filters: [
      { "store": "sysCodes", "value": "", "name": "bridge_material", "fld_name": "bridge_material" },
    ],
    bridges: [],
    elementSoundness: [],

  }),
  computed: {
    description() {
      return this.$i18n.locale === "la" ? "description_lao" : "description_eng";
    },
  },

  async mounted() {
    this.bridges = (await BridgeService.index()).data.map(bridge => ({
      ...bridge,
      hi_data_quality: bridge.element_max_computed_hi > 100 ? 'CHECK' : 'OK'
    }));
    this.elementSoundness = (await BridgeService.getBridgeSoudness(new Date().getFullYear())).data;
  },
};
</script>
